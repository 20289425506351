import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import SEO from '../common/seo'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

const NotFoundPage = () => {
  const props = useMotionNavOffsetStyle({ cssProp: 'padding' })
  return (
    <Container {...props}>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  )
}

export default NotFoundPage

const Container = styled(motion.div)`
  max-width: ${(p) => p.theme.breakpoints.values.md}px;
  margin: auto;
`
